import { useWindowSize } from "./useWindowSize";

export const useViewPortHeightWithScroll = () => {
  const { innerHeightWithoutScrollBar } = useWindowSize();

  const root = document.getElementById("root");
  if (root) {
    root.style.height = `${innerHeightWithoutScrollBar + 1}px`;
  }
};
