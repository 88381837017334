import clsx from "clsx";
import { FormApi } from "final-form";
import React from "react";
import { Field, Form } from "react-final-form";
import { Input } from "src/components/ui-kit/Input";
import { composeValidators } from "src/libs/validators/compose-validators";
import { isRequired } from "src/libs/validators/is-required";
import { AuthFormValues } from "src/pages/types/auth-form-values";

import cls from "./AuthForm.module.scss";


interface AuthFormProps {
  handleFormSubmit: (values: AuthFormValues) => void;
  hasBackendError: boolean;
  isSubmitting: boolean;
}

export const AuthForm: React.FC<AuthFormProps> = ({
  handleFormSubmit,
  hasBackendError = false,
  isSubmitting = false,
}) => {
  const handleLoginChange = (
    value: string,
    form: FormApi<AuthFormValues, Partial<AuthFormValues>>
  ) => {
    form.change("login", value);
  };
  const handlePasswordChange = (
    value: string,
    form: FormApi<AuthFormValues, Partial<AuthFormValues>>
  ) => {
    form.change("password", value);
  };

  return (
    <section className={cls.sectionWrapper}>
      <div className={cls.contentWrapper}>
        <h1 className={cls.pageTitle}>Log in</h1>
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <form
              onSubmit={handleSubmit}
              className={clsx(cls.form, {
                [cls["form--error"]]: submitFailed || hasBackendError,
              })}
            >
              <div className={cls.inputsWrapper}>
                <Field name="login" validate={composeValidators([isRequired])}>
                  {({ input, meta }) => (
                    <label className={cls.labelWrapper}>
                      <span className={cls.labelText}>Login</span>
                      <Input
                        {...input}
                        className={clsx(cls.input, cls["input--login"], {
                          [cls["input--errors"]]: meta.error && submitFailed,
                        })}
                        onChange={(evt) =>
                          handleLoginChange(evt.currentTarget.value, form)
                        }
                        hasError={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && (
                        <span className={cls.errorText}>{meta.error}</span>
                      )}
                    </label>
                  )}
                </Field>
                <Field
                  name="password"
                  validate={composeValidators([isRequired])}
                >
                  {({ input, meta }) => (
                    <label className={cls.labelWrapper}>
                      <span className={cls.labelText}>PassWord</span>
                      <Input
                        {...input}
                        type="password"
                        className={clsx(cls.input, cls["input--password"], {
                          [cls["input--errors"]]: meta.error && submitFailed,
                        })}
                        onChange={(evt) =>
                          handlePasswordChange(evt.currentTarget.value, form)
                        }
                        hasError={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && (
                        <span className={cls.errorText}>{meta.error}</span>
                      )}
                    </label>
                  )}
                </Field>
              </div>
              <button
                className={cls.submitButton}
                type="submit"
                disabled={(submitFailed && hasValidationErrors) || isSubmitting}
              >
                login
              </button>
            </form>
          )}
        </Form>
      </div>
      <span className={cls.requiredNotion}>Обязательные поля</span>
    </section>
  );
};
