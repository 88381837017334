import axios from "axios";
import Cookie from "js-cookie";

// Выбираем URL на основе значения куки `usePlanFact`
const isPlanFact = Cookie.get("usePlanFact") === "true";
const baseURL = isPlanFact
  ? process.env.REACT_APP_API_URL_PLAN_FACT
  : process.env.REACT_APP_API_URL_UNIT_FACT;

export const api = axios.create({
  baseURL,
  responseType: "json",
  withCredentials: true,
  timeout: 10000,
});

// Функция для получения accessToken (например, из localStorage или cookies)
function getAccessToken() {
  return Cookie.get("accessToken") || null;
}

// Перехватчик для добавления accessToken к каждому запросу
api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Перехватчик для обработки ошибок (например, 401 - невалидный или истекший токен)
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Токен не валиден или отсутствует, перенаправляем на страницу /login
      // Router.push("/login");
    }
    return Promise.reject(error);
  }
);
