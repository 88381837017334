import { useEffect, useState } from "react"

import { getScrollBarWidth } from "../helpers/getScrollBarWidth";

export const useWindowSize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [scrollbarWidth, setScrollBarWidth] = useState(getScrollBarWidth());

  useEffect(() => {
    const handleWindowResize = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
      setScrollBarWidth(window.innerWidth - document.documentElement.clientWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return window.removeEventListener("resize", handleWindowResize)
  });

  return {innerWidth, innerHeightWithoutScrollBar: innerHeight - scrollbarWidth };
}