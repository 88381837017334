export const getSubscriptionStatus = ({
  createdAt,
  expireDate,
}: {
  createdAt: Date | string;
  expireDate: Date | string;
}): "active" | "off" => {
  const createdTime = new Date(createdAt).getTime();
  const expireTime = new Date(expireDate).getTime();

  return expireTime > createdTime ? "active" : "off";
};
