export type SearchParams = {
  page?: string;
  pageSize?: string;
  filter?: string;
  sort?: string;
}

export const transformSearchParams = (params: URLSearchParams): SearchParams  => {
  return Object.fromEntries(params);
};
