import Cookie from "js-cookie";
import { api } from "src/services/api-service/api";

import { mapUserResponse } from "./helpers/map-user-response";
import {
  UserDataMapped,
  UserLoginRequest,
  UserLoginResponse,
  UserPatchRequestBody,
  UserRequestParams,
} from "./types";
import { UserRoutes } from "./users.route";

export const fetchUserRequest = async (
  params: UserRequestParams
): Promise<{
  users: UserDataMapped[]
  totalCount: number
}> =>
  api
    .get(UserRoutes.Users(), { params })
    .then((res) => {
      return {
        users: mapUserResponse(res.data.users),
        totalCount: res.data.total
      }
    });

export const loginUser = async (body: UserLoginRequest) =>
  api.post<UserLoginResponse>(UserRoutes.Login(), body).then((res) => {
    if (res.data && res.data.token) {
      console.log('set token');

      Cookie.set("accessToken", res.data.token);
      return true;
    }
  });

export const patchUserRequest = async ({
  userId,
  body,
  onSuccess,
}: {
  userId: string;
  body: UserPatchRequestBody;
  onSuccess?: () => void;
}): Promise<void> =>
  api
    .patch(UserRoutes.PatchUser(), { userId, ...body })
    .then(() => onSuccess?.());

export const deleteUserRequest = async ({
  userId,
  onSuccess,
}: {
  userId: string;
  onSuccess?: () => void;
}): Promise<void> =>
  api.delete(UserRoutes.Delete(userId)).then(() => onSuccess?.());
