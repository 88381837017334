import { useEffect, useState } from "react";

import { BreakPoint } from "../const/breakpoints";

export const useAdaptive = (
  device: "mobile" | "tablet" | "laptop" | "desktop"
) => {
  let query = "";
  switch (device) {
    case "mobile":
      query = `(max-width: ${BreakPoint.MobileTop})`;
      break;
    case "tablet":
      query = `(min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop})`;
      break;
    case "laptop":
      query = `(min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop})`;
      break;
    case "desktop":
      query = `(min-width: ${BreakPoint.DesktopLow})`;
  }
  const mediaQuery: MediaQueryList = window.matchMedia(query);
  const [isMatchDevice, setIsMatchDevice] = useState(mediaQuery.matches);

  useEffect(() => {
    const handleResize = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setIsMatchDevice(true);
      } else {
        setIsMatchDevice(false);
      }
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, [mediaQuery]);

  return isMatchDevice;
};
