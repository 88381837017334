import { useNavigate } from "react-router-dom";
import { AuthForm } from "src/pages/PageLogin/components/AuthForm/AuthForm";
import { AuthFormValues } from "src/pages/types/auth-form-values";
import { loginUser } from "src/services/api-service/users/users.service";
import { FetchStatus } from "src/services/types";

export const PageLogin = () => {
  //TODO взять значение из стора/стейта
  const loginFetchStatus: FetchStatus = "initial";
  const navigate = useNavigate();

  const handleFormSubmit = async (body: AuthFormValues) => {
    const result = await loginUser(body);

    if (result) {
      console.log('login');

      navigate('/admin')
    }
  };

  return (
    <AuthForm
      handleFormSubmit={handleFormSubmit}
      //TODO Раскоментить после получения статуса из стора и удалить лишнее
      // hasBackendError={loginFetchStatus === "rejected"}
      // isSubmitting={loginFetchStatus === 'pending'}
      hasBackendError={false}
      isSubmitting={false}
    />
  );
};
