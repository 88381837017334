import { RefObject, useCallback, useEffect } from 'react';

export const useOuterClick = ({
  excludedElements,
  onClickCb,
}: {
  excludedElements: RefObject<HTMLElement>[];
  onClickCb: () => void;
}): void => {
  const clickIsOutOfElements = useCallback(
    (event: Event) =>
      excludedElements.every(
        (element) => element.current && !element.current?.contains(event.target as Node),
      ),
    [excludedElements],
  );

  const handleOutsideClick = useCallback(
    (event: Event) => {
      if (clickIsOutOfElements(event)) {
        onClickCb();
      }
    },
    [clickIsOutOfElements, onClickCb],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, true);
    return () => document.removeEventListener('mousedown', handleOutsideClick, true);
  }, [handleOutsideClick]);
};
