import React from "react";
import { useViewPortHeightWithScroll } from "src/libs/hooks/useViewPortHeightWithScroll";

import cls from "./OuterHeader.module.scss";

export const OuterHeader = () => {
  useViewPortHeightWithScroll ();

  return (
    <div className={cls.wrapper}>
      OUTER HEADER
    </div>
 );
};
