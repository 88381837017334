import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ru } from "date-fns/locale/ru";
import { useAdaptive } from "src/libs/hooks/useAdaptive";

import cls from "./UserEdit.module.scss";

interface UserEditProps {
  apiKeyCb: (apiKey: string) => void;
  dateCb: (expireDate: Date | null) => void;
  apiKey: string;
  endDate: Date | null;
}

export const UserEdit: React.FC<UserEditProps> = ({
  apiKey,
  endDate,
  dateCb,
  apiKeyCb,
}) => {
  const isMobile = useAdaptive("mobile");

  console.log(endDate, 'endDate');


  const handleDateChange = (newValue: Date | null) => {
    if (!newValue) {
      return;
    }

    dateCb(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <div className={cls.wrapper}>
        <label className={cls.label}>
          apiKey
          <input
            className={cls.input}
            type="text"
            placeholder="apiKey"
            value={apiKey}
            onChange={({ target }) => apiKeyCb(target.value)}
          />
        </label>
        <label className={cls.datepickerLabel}>
          {isMobile ? (
            <DateTimePicker
              label="Дата окончания подписки"
              value={endDate}
              onChange={handleDateChange}
            />
          ) : (
            <MobileDateTimePicker
              label="Дата окончания подписки"
              value={endDate}
              onChange={handleDateChange}
            />
          )}
        </label>
      </div>
    </LocalizationProvider>
  );
};
