export interface INavItem {
  linkTo: string;
  text: string;
}

export const navItems: INavItem[] = [
  {
    linkTo: "/admin",
    text: "Пользователи",
  },
];
