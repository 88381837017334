import clsx from "clsx";
import React from "react";

import cls from "./UserDelete.module.scss";

interface UserDeleteProps {
  onHandleConfirmation: (action: "delete" | "cancel") => void;
  closeModal: () => void;
}
export const UserDelete: React.FC<UserDeleteProps> = ({onHandleConfirmation, closeModal}) => {

  const handleDeleteClick = () => {
    onHandleConfirmation("delete");
    closeModal();
  }

  const handleCancelClick = () => {
    onHandleConfirmation("cancel");
    closeModal();
  }
  return (
    <div className={cls.wrapper}>
      <button
        className={clsx(cls.button, cls["button--cancel"])}
        onClick={handleCancelClick}
      >
        Отмена
      </button>
      <button
        className={clsx(cls.button, cls["button--delete"])}
        onClick={handleDeleteClick}

        >
          Удалить
        </button>
    </div>
  );
};
