import "./style/index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "src/components/App/App";

import { StorageKey } from "./libs/types/storage-key.enum";

//удаляем старый и сохраняем новый  путь, который ввел пользователь в url
// чтобы обратно редиректнуть после логина на этот самый url
localStorage.removeItem(StorageKey.OuterRoute);
const outerRoute = window.location.pathname;
localStorage.setItem(StorageKey.OuterRoute, outerRoute);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
