import { formatDate } from "src/libs/helpers/getFormatedDate";

import { UserDataMapped,UsersItem } from "../types";

export const mapUserResponse = (
  usersServerResponse: UsersItem[]
): UserDataMapped[] => {
  return usersServerResponse.map((user) => ({
    id: String(user.id) ?? "",
    phone: user.phone ?? "",
    apiKey: user.key ?? "",
    createdAt: user.createdAt ? formatDate(user.createdAt) : "",
    subscription: {
      isPaid: user.subscription?.isPaid ?? false,
      endDate: user.subscription?.endDate
        ? formatDate(user.subscription?.endDate)
        : "",
      createdAt: user.subscription?.createdAt
        ? formatDate(user.subscription?.createdAt)
        : "",
      status:
        user.subscription &&
        user.subscription.endDate &&
        user.subscription.createdAt
          ? new Date(user.subscription.endDate).getTime() -
              new Date(user.subscription.createdAt).getTime() >
            0
            ? "active"
            : "off"
          : "off",
    },
  }));
};
