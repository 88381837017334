import clsx from "clsx";
import React, { FC } from "react";
import { Link } from "react-router-dom";

import cls from "./NavigationItem.module.scss";

interface NavigationItemProps {
  linkTo: string;
  text: string;
  isCurrent: boolean;
  onClick?: () => void;
}
export const NavigationItem: FC<NavigationItemProps> = ({
  linkTo,
  text,
  isCurrent,
  onClick,}) => {

  return (
    <li className={cls.navListItem}>
      <Link
        className={clsx(cls.linkItem, {
          [cls["linkItem--current"]]: isCurrent})}
        to={linkTo}

      >
        {text}
      </Link>
    </li>
 );
};
