import React from "react";

import { Navigation } from "../Navigation/Navigation";
import { NavigationFooter } from "../NavigationFooter/NavigationFooter";
import cls from "./MainMenu.module.scss";

export const MainMenu = () => {
  return (
    <div className={cls.mainMenuWrapper}>
      <Navigation />
      <NavigationFooter />
    </div>
  );
};
