import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatDate } from "src/libs/helpers/getFormatedDate";
import { getParams } from "src/libs/helpers/getSearchParams";
import { getSubscriptionStatus } from "src/libs/helpers/getSubscriptionStatus";
import { TableValues } from "src/pages/PageAdmin/components/UsersTable/types/table-values.interface";
import { UserTableWithLocalizationProvider } from "src/pages/PageAdmin/components/UserTableWithLocalizationProvider/UserTableWithLocalizationProvider";
import { UserDataMapped } from "src/services/api-service/users/types";
import {
  deleteUserRequest,
  fetchUserRequest,
  patchUserRequest,
} from "src/services/api-service/users/users.service";

import { UsersTable } from "./components/UsersTable/UsersTable";
import cls from "./PageAdmin.module.scss";

export const PageAdmin = () => {
  const [searchParams] = useSearchParams();

  const [users, setUsers] = useState<UserDataMapped[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);

  const handlePatchUser = (values: TableValues) => {
    const onSuccess = () => {
      const updatedUser: UserDataMapped = {
        id: values.id,
        phone: values.phone,
        apiKey: values.apiKey,
        createdAt: values.createdAt,
        subscription: {
          isPaid: values.isPaid,
          endDate: formatDate(values.subExpired),
          createdAt: values.subCreated,
          status: getSubscriptionStatus({
            createdAt: new Date(values.subCreated),
            expireDate: values.subExpired?.toString() || "",
          }),
        },
      };
      const selectedUserIndex = users.findIndex(
        (user) => user.id === values.id
      );
      const updatedUsers = users.slice();
      if (selectedUserIndex >= 0) {
        updatedUsers.splice(selectedUserIndex, 1, updatedUser);
        setUsers(updatedUsers);
      }
    };

    patchUserRequest({
      userId: values.id,
      body: {
        apiKey: values.apiKey,
        expireDate: values.subExpired as unknown as Date,
      },
      onSuccess,
    });
  };

  const handleDeleteUser = (userId: string) => {
    const onSuccess = () => {
      const selectedUserIndex = users.findIndex((user) => user.id === userId);
      const updatedUsers = users.slice();
      if (selectedUserIndex >= 0) {
        updatedUsers.splice(selectedUserIndex, 1);
        setUsers(updatedUsers);
      }
    };
    deleteUserRequest({ userId, onSuccess });
  };


  useEffect(() => {
    const params = getParams(searchParams);

    setIsLoading(true);
    fetchUserRequest({
      page: Number(params.page),
      size: Number(params.pageSize),
      search: params.filter,
    })
      .then((res) => {
        setUsers(res.users);
        setTotalCount(res.totalCount)
      })
      //TODO добавить нотификацию
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }, [searchParams]);

  return (
    <div className={cls.wrapper}>
      <UsersTable
        usersData={users}
        totalCount={totalCount}
        isLoading={isLoading}
        onPatchUser={handlePatchUser}
        onDeleteUser={handleDeleteUser}
      />
    </div>
  );
};
