import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { TUserAuthStatus } from "src/libs/types/user-auth-status.type";
import { PageAdmin } from "src/pages/PageAdmin/PageAdmin";
import { PageLogin } from "src/pages/PageLogin/PageLogin";

import { InnerLayout } from "../InnerLayout/InnerLayout";
import { OuterLayout } from "../OuterLayout/OuterLayout";

// Компонент PrivateRoute для защиты приватных маршрутов
const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const accessToken = Cookies.get("accessToken");

  if (accessToken) {
    return element;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const App = () => {
  const [userAuthStatus, setUserAuthStatus] = useState<TUserAuthStatus>("anonymous");

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");

    if (accessToken) {
      setUserAuthStatus("authorized");
    } else {
      setUserAuthStatus("unauthorized");
    }
  }, []);

  if (userAuthStatus === "anonymous") {
    return null; // Ожидание проверки авторизации
  }

  return (
    <Routes>
      {/* Приватные маршруты */}
      <Route
        path="/"
        element={
          <PrivateRoute element={<InnerLayout />} />
        }
      >
        <Route index element={<Navigate to="/admin" replace />} />
        <Route path="/admin" element={<PageAdmin />} />
      </Route>

      {/* Публичные маршруты */}
      <Route path="/" element={<OuterLayout />}>
        <Route path="/login" element={<PageLogin />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Route>
    </Routes>
  );
};
