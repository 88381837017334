import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { useMounted } from "src/libs/hooks/useMounted";

interface PortalProps {
  children: ReactNode;
  elementId?: string;
}
export const Portal = ({ children, elementId }: PortalProps) => {
  const customElement = elementId && document.getElementById("elementId");

  const { isMounted } = useMounted();
  if (!isMounted) {
    return null;
  }

  const element: HTMLElement = customElement ? customElement : document.body;

  return createPortal(children, element);
};
