import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import cls from "./NavigationFooter.module.scss";

export const NavigationFooter = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log("logout");

    Cookies.remove("accessToken");
    navigate("/login");
  };

  return (
    <div className={cls.footerWrapper}>
      <button className={cls.logoutButton} onClick={handleLogout}>
        Выйти
      </button>
    </div>
  );
};
