import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import { parse } from "date-fns";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_PaginationState,
  MRT_Row,
  MRT_TableOptions,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { parseDateWithTime } from "src/libs/helpers/parseDateWithTime";
import { transformSearchParams } from "src/libs/helpers/transformSearchParams";
import { hiddenFirstSymbols } from "src/libs/utils/hidden-first-symbols";
import { UserDataMapped } from "src/services/api-service/users/types";

import { Modal } from "../../../../components/ui-kit/Modal/Modal";
import { UserDelete } from "../UserDelete/UserDelete";
import { UserEdit } from "../UserEdit/UserEdit";
import { TableValues } from "./types/table-values.interface";
import cls from "./UsersTable.module.scss";

interface UserTableProps {
  usersData: UserDataMapped[];
  totalCount: number;
  onPatchUser: (value: TableValues) => void;
  onDeleteUser: (userId: string) => void;
  isLoading: boolean;
}
export const UsersTable: FC<UserTableProps> = ({
  usersData,
  totalCount,
  onPatchUser,
  onDeleteUser,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userId, setUserId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [subExpired, setSubExpired] = useState<Date | null>(null);

  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    console.log(searchParams, 'searchParams');
    console.log(pagination, 'pagination');


    const params = transformSearchParams(searchParams);
    params.page = String(pagination.pageIndex + 1);
    params.pageSize = String(pagination.pageSize);
    params.filter = globalFilter;
    if (!globalFilter) {
      delete params.filter;
    }
    console.log(params, 'before set');

    setSearchParams(params);
  }, [
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    setSearchParams,
  ]);

  const columns = useMemo<MRT_ColumnDef<UserDataMapped>[]>(
    () => [
      {
        accessorKey: "id",
        id: "id",
        header: "UserId",

        Cell: ({ cell }) => (
          <Box className={cls.cell}>{cell.getValue<string>()}</Box>
        ),
      },
      {
        accessorKey: "phone",
        header: "Телефон",
        enableColumnFilter: true,
        enableSorting: true,
        enableClickToCopy: true,
      },
      {
        accessorKey: "apiKey",
        header: "apiKey",
        enableEditing: false,
        Cell: ({ cell }) => hiddenFirstSymbols(cell.getValue<string>(), 4),
      },
      {
        accessorFn: (user: UserDataMapped) => user.createdAt, //must be Date for sorting and filtering
        id: "createdAt",
        header: "Дата регистрации",
        enableColumnFilter: true,
        filterVariant: "date-range",
        enableSorting: true,
        enableEditing: false,
        sortingFn: "datetime",
        muiTableHeadCellProps: {
          sx: {
            borderRight: "1px solid #c9c9c9",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderRight: "1px solid #c9c9c9",
          },
        },
      },
      {
        accessorFn: (user: UserDataMapped) => user.subscription.createdAt,
        id: "subCreated",
        header: "Подписка создана",
        enableColumnFilter: true,
        filterVariant: "date-range",
        enableSorting: true,
        sortingFn: "datetime",
      },
      {
        accessorFn: (user: UserDataMapped) => user.subscription.endDate,
        id: "subExpired",
        header: "Подписка истекает",
        enableColumnFilter: true,
        filterVariant: "date-range",
        enableSorting: true,
        sortingFn: "datetime",
      },
      {
        accessorFn: (user: UserDataMapped) => user.subscription.status,
        id: "status",
        header: "Статус",
        enableEditing: true,
        Cell: ({ cell }) => {
          const value = cell.getValue<"active" | "off">();
          return (
            <Box className={clsx(cls.cell, cls[`cell--${value}`])}>
              {cell.getValue<string>()}
            </Box>
          );
        },
      },
      {
        accessorFn: (user: UserDataMapped) => user.subscription.isPaid,
        id: "isPaid",
        header: "isPaid",
        enableEditing: false,
        visibleInShowHideMenu: false,
      },
    ],
    []
  );

  const handlePatchUser: MRT_TableOptions<UserDataMapped>["onEditingRowSave"] =
    async ({ row }) => {
      onPatchUser({
        id: row.original.id,
        apiKey: apiKey || row.original.apiKey || "",
        subExpired:
          subExpired?.toISOString() || parseDateWithTime(row.original.subscription.endDate) || "",
        createdAt: row.original.createdAt,
        phone: row.original.phone,
        isPaid: row.original.subscription.isPaid,
        subCreated: row.original.subscription.createdAt,
      });
      table.setEditingRow(null); // закрывает модалку
    };

  const handleDeleteUser = (row: MRT_Row<UserDataMapped>) => {
    const userId = row.original.id;
    setIsModalActive(true);
    setUserId(userId);
  };

  const handleUserDeleteConfirmation = (action: "delete" | "cancel") => {
    if (action === "delete") {
      onDeleteUser(userId);
      return;
    }
  };

  const table = useMaterialReactTable({
    data: usersData,
    columns,
    localization: MRT_Localization_RU,
    getRowId: (row) => row.id,
    editDisplayMode: "modal",
    enableEditing: true,

    enableRowSelection: false,
    enableRowActions: true,
    onEditingRowSave: handlePatchUser,

    initialState: {
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-actions"],
      },
      columnVisibility: { isPaid: false },
    },
    state: {
      globalFilter,
      pagination,
    },
    rowCount: totalCount,
    manualFiltering: false, // разрешает автоматическую фильрацию
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: true,
    onPaginationChange: setPagination,
    // paginationDisplayMode: "pages", //меняю режим отображения пагинации
    // muiPaginationProps: {
    //   //кастомизирую пагинацию
    //   color: "secondary",
    //   rowsPerPageOptions: [3, 10, 20, 30],
    //   shape: "rounded",
    //   variant: "outlined",
    // },

    //модалка для фичи редактирования пользователя
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
      const handleApiKeyField = (fieldValue: string) => {
        setApiKey(fieldValue);
      };
      const handleDateExpiredField = (fieldValue: Date | null) => {
        setSubExpired(fieldValue);
      };

      return (
        <>
          <DialogTitle variant="h5">Изменить данные пользователя</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <UserEdit
              apiKey={apiKey || row.original.apiKey}
              endDate={
                subExpired || parseDateWithTime(row.original.subscription.endDate)
              }
              apiKeyCb={handleApiKeyField}
              dateCb={handleDateExpiredField}
            />
          </DialogContent>
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </>
      );
    },

    //активация кнопок редактирования и удаления
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row); //откроет модалку для изменений
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteUser(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {isModalActive && (
        <Modal
          mode="window"
          closeModal={() => setIsModalActive(false)}
          title={"Подтвердите удаление пользователя"}
          hasCloseIcon={false}
        >
          <UserDelete
            closeModal={() => setIsModalActive(false)}
            onHandleConfirmation={handleUserDeleteConfirmation}
          />
        </Modal>
      )}
    </>
  );
};
