import { MenuItem, Select } from "@mui/material";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { NavigationItem } from "../NavigationItem/NavigationItem";
import { navItems } from "./constants/nav-items";
import cls from "./Navigation.module.scss";

export const Navigation = () => {
  const { pathname } = useLocation();

  const [isPlanFact, setIsPlanFact] = useState(
    Cookie.get("usePlanFact") === "true"
  );

  const handleSelectChange = (event: any) => {
    const newValue = event.target.value === "planFact";
    setIsPlanFact(newValue);
    Cookie.set("usePlanFact", newValue.toString());

    window.location.reload();
  };

  useEffect(() => {
    // Обновляем куку при первой загрузке, если она не установлена
    if (Cookie.get("usePlanFact") === undefined) {
      Cookie.set("usePlanFact", "true");
    }
  }, []);

  return (
    <>
      <Select
        value={isPlanFact ? "planFact" : "unitFact"}
        onChange={handleSelectChange}
        className={cls.select}
        variant="outlined"
        size="small"
      >
        <MenuItem value="planFact">План факт</MenuItem>
        <MenuItem value="unitFact">Юнит факт</MenuItem>
      </Select>
      <nav className={cls.navWrapper}>
        <ul className={cls.navList}>
          {navItems.map((item) => (
            <NavigationItem
              key={item.linkTo}
              linkTo={item.linkTo}
              text={item.text}
              isCurrent={pathname === item.linkTo}
            />
          ))}
        </ul>
      </nav>
    </>
  );
};
