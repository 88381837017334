type Response = (value: string) => string | undefined;
type ValidatorsFn = (value: string) => string | undefined;

export const composeValidators = (validatorsFn: ValidatorsFn[]): Response => {
  return (
    function(value) {
      for (const validator of validatorsFn) {
        const error = validator(value);
        if (error) {
          return error;
        }
      }
    }
  );
};
