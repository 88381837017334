import clsx from "clsx";
import React, { ReactNode, useRef } from "react";
import { ReactComponent as CloseIcon } from "src/assets/button-icons/close-icon.svg";
import { useBlockContentScroll } from "src/libs/hooks/useBlockContentScroll";
import { useCloseByEsc } from "src/libs/hooks/useCloseByEsc";
import { useOuterClick } from "src/libs/hooks/useOuterClick";

import { Portal } from "../Portal/Portal";
import cls from "./Modal.module.scss";

interface ModalProps {
  mode?: "window" | "drop";
  children: ReactNode;
  closeModal: () => void;
  title?: string;
  hasCloseIcon?: boolean;
}

export const Modal = ({
  mode = "window",
  children,
  closeModal,
  title,
  hasCloseIcon = true,
}: ModalProps) => {
  const popupRef = useRef(null);

  const excludedFromOuterClickElements = [popupRef];
  useBlockContentScroll();
  useOuterClick({
    excludedElements: excludedFromOuterClickElements,
    onClickCb: closeModal,
  });
  useCloseByEsc({ isShown: !!useRef, cb: closeModal });

  return (
    <Portal>
      <div className={cls.overlay}>
        <div className={clsx(cls.modalWrapper, cls[mode])} ref={popupRef}>
          <div className={cls.headerWrapper}>
            {title && <h3>{title}</h3>}
            <button
              onClick={closeModal}
              className={clsx(cls.closeButton, cls[`closeButton--${mode}`])}
            >
              {hasCloseIcon && <CloseIcon />}
            </button>
          </div>
          <div
            className={clsx(cls.contentWrapper, cls[`contentWrapper${mode}`])}
          >
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
