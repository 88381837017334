import { Outlet } from "react-router-dom";
import { useViewPortHeightWithScroll } from "src/libs/hooks/useViewPortHeightWithScroll";

import { Header } from "../Header/Header";
import { MainMenu } from "../MainMenu/MainMenu";
import cls from "./InnerLayout.module.scss";

export const InnerLayout = () => {
  useViewPortHeightWithScroll();

  return (
    <div className={cls.wrapper}>
      <div className={cls.headerWrapper}>
        <Header />
      </div>
      <div className={cls.navigationMenuWrapper}>
        <MainMenu />
      </div>
      <main className={cls.contentWrapper}>
        <div className={cls.contentScroll}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
