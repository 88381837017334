import clsx from "clsx";
import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useState,
} from "react";
import { ReactComponent as EyeClosed } from 'src/assets/button-icons/eye-closed.svg';
import { ReactComponent as EyeOpened } from 'src/assets/button-icons/eye-opened.svg';

import cls from "./Input.module.scss";

interface IInputProps
  extends Partial<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  > {
  variant?: "primary" | "password";
  hasError?: boolean;
  isDisabled?: boolean;
  leftIcon?: ReactElement | null;
  leftText?: string;
  className?: string;
  type?: string;
  inputMode?: "text" | "numeric" | "search";
}

export const Input = forwardRef<HTMLInputElement | null, IInputProps>(
  (
    {
      variant = "primary",
      hasError = false,
      isDisabled = false,
      leftIcon,
      leftText,
      className,
      inputMode,
      type,
      ...props
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type);

    const hasPasswordType = type === 'password';
    const hasLeftIcon = !!leftIcon;
    const hasLeftText = !!leftText;

    const togglePasswordVisibility = (): void =>
      setInputType((current) => (current === 'password' ? "text" : "password"));

    return (
      <div
        className={clsx(cls.wrapper, cls[variant], {
          [cls.hasLeftIcon]: hasLeftIcon,
          [cls.hasLeftText]: hasLeftText,
          [cls.hasError]: hasError,
        })}
      >
        {hasLeftIcon && leftIcon}
        {hasLeftText && <span className={cls.secondaryText}>{leftText}</span>}
        <input
          {...props}
          ref={ref}
          disabled={isDisabled}
          className={clsx(className, cls.basicInput)}
          inputMode={inputMode}
          type={inputType}
        />

         {hasPasswordType && (
          <button
            className={cls.eyeButton}
            type="button"
            onClick={togglePasswordVisibility}
            disabled={isDisabled}
            >
            {inputType === 'password' ? <EyeClosed /> : <EyeOpened />}
          </button>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";
