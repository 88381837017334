import React from "react";
import { Outlet } from "react-router-dom";

import { OuterHeader } from "../OuterHeader/OuterHeader";
import cls from "./OuterLayout.module.scss";

export const OuterLayout = () => {
  return (
    <div className={cls.wrapper}>
      <div className={cls.headerWrapper}>
        <OuterHeader />
      </div>
      <div className={cls.contentWrapper}>
        <Outlet />
      </div>
    </div>
  );
};
